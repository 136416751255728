import StoreContext from "../store/RootStore";
import AnchorLinkBrowserTransport from "anchor-link-browser-transport";
import AnchorLink from "anchor-link";
import * as waxjs from "@waxio/waxjs/dist";
import { NotificationManager } from "react-notifications";
import eosjsName from "eosjs-account-name";

export default class LoginHandler {
  constructor() {
    const { AccountStore } = StoreContext();
    this.AccountStore = AccountStore;
    const transport = new AnchorLinkBrowserTransport();
    this.aa = "https://wax-atomic-api.eosphere.io";
    this.endpoint = "https://wax.api.eosnation.io";
    this.anchorLink = new AnchorLink({
      transport,
      chains: [
        {
          chainId:
            "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
          nodeUrl: this.endpoint,
        },
      ],
    });
    this.wax = new waxjs.WaxJS({
      rpcEndpoint: "https://wax.api.eosnation.io",
      tryAutoLogin: false,
    });
    this.dapp = "dovutilstake";
    this.tokenContract = "dovvaultfort";
    this.Utilcontract = "dovutilstake";
    this.contract = "dovsmartrepo";
    this.Gamecontract = "dovpvecontrl";
    this.collection = "dovdivisions";
  }

  async checkAdmin(userName) {
    var check = false;
    try {
      const tableRows = await this.wax.rpc.get_table_rows({
        json: true,
        code: "dovutilstake",
        scope: userName,
        table: "balances",
        limit: 1000,
      });
      if (tableRows.rows.length === 0) {
        return false;
      } else {
        for (let i = 0; i < tableRows.rows.length; i++) {
          if (tableRows.rows[i].balance.includes("ADMIN")) {
            if (parseInt(tableRows.rows[i].balance) > 0) {
              check = true;
            }
          }
        }
        return check;
      }
    } catch (e) {
      NotificationManager.error("Error", "Error", 5000);
    }
  }

  async getUserBalancetkn() {
    let balances = {
      dovx: "0.00",
      dovr: "0.00",
      dovs: "0.00",
      dovf: "0.00",
      dovh: "0.00",
      avt: 1,
    };
    try {
      const tableRows = await this.wax.rpc.get_table_rows({
        json: true,
        code: "dovutilstake",
        scope: this.AccountStore.accountAddress,
        table: "balances",
      });
      if (tableRows.rows.length === 0) {
      } else {
        for (let i = 0; i < tableRows.rows.length; i++) {
          if (tableRows.rows[i].balance.includes("DOVX")) {
            balances.dovx = parseFloat(tableRows.rows[i].balance);
          } else if (tableRows.rows[i].balance.includes("DOVR")) {
            balances.dovr = parseFloat(tableRows.rows[i].balance);
          } else if (tableRows.rows[i].balance.includes("DOVS")) {
            balances.dovs = parseFloat(tableRows.rows[i].balance);
          } else if (tableRows.rows[i].balance.includes("DOVF")) {
            balances.dovf = parseFloat(tableRows.rows[i].balance);
          } else if (tableRows.rows[i].balance.includes("DOVH")) {
            balances.dovh = parseFloat(tableRows.rows[i].balance);
          } else if (tableRows.rows[i].balance.includes("AVT")) {
            balances.avt = parseInt(tableRows.rows[i].balance);
          }
        }
        this.AccountStore.setBlcData(balances);
        if (this.AccountStore.avatarData.length === 0) {
          this.getAvConfig();
        }
        return balances;
      }
    } catch (e) {}
  }

  async getConfig() {
    const body = await this.wax.rpc.get_table_rows({
      json: true,
      code: "dovutilstake",
      scope: "dovutilstake",
      table: "configs",
      limit: 4,
    });
    const data = body.rows;
    if (data.length !== 0) {
      let dataa = data[0];
      this.AccountStore.setConfig(dataa);
    }
  }

  async getAvConfig() {
    const body = await this.wax.rpc.get_table_rows({
      json: true,
      code: this.Gamecontract,
      scope: this.Gamecontract,
      table: "avatars",
      limit: 100,
    });
    const data = body.rows;
    if (data.length !== 0) {
      this.AccountStore.setAvatarConfig(data);
    }
  }

  async getUserBalancewax() {
    try {
      let balancez = {
        dovx: "0.00",
        dovr: "0.00",
        dovs: "0.00",
        dovf: "0.00",
        dovh: "0.00",
      };
      const tableRows = await this.wax.rpc.get_table_rows({
        json: true,
        code: this.tokenContract,
        scope: this.AccountStore.accountAddress,
        table: "accounts",
      });
      if (tableRows.rows.length === 0) {
      } else {
        for (let i = 0; i < tableRows.rows.length; i++) {
          if (tableRows.rows[i].balance.includes("DOVX")) {
            balancez.dovx = parseFloat(tableRows.rows[i].balance);
          } else if (tableRows.rows[i].balance.includes("DOVR")) {
            balancez.dovr = parseFloat(tableRows.rows[i].balance);
          } else if (tableRows.rows[i].balance.includes("DOVS")) {
            balancez.dovs = parseFloat(tableRows.rows[i].balance);
          } else if (tableRows.rows[i].balance.includes("DOVF")) {
            balancez.dovf = parseFloat(tableRows.rows[i].balance);
          } else if (tableRows.rows[i].balance.includes("DOVH")) {
            balancez.dovh = parseFloat(tableRows.rows[i].balance);
          }
        }
        this.AccountStore.setWBlcData(balancez);
        return balancez;
      }
    } catch (e) {}
  }

  async login(data) {
    try {
      this.AccountStore.changeAccountAddress(data.accountName);
      this.AccountStore.setUserData(data);
    } catch (e) {
      NotificationManager.error(e, "Error while logging in through Anchor");
    }
  }

  async getAssets() {
    try {
      const assets = [];
      const response = await fetch(
        this.aa +
          `/atomicassets/v1/assets?collection_name=${this.collection}&owner=${this.AccountStore.accountAddress}&page=1&limit=1000&order=desc&sort=asset_id`,
        {
          headers: {
            "Content-Type": "text/plain",
          },
          method: "GET",
        }
      );
      const body = await response.json();
      for (let i = 0; i < body.data.length; i++) {
        let data = body.data[i];
        assets.push({
          asset_id: data.asset_id,
          img: data.data.img,
          name: data.name,
          template_id: data.template.template_id,
          schema: data.schema.schema_name,
          fusion: data.data.fusion,
          rarity: data.data.rarity,
        });
      }
      await this.getUnitsConfig(assets);
      await this.getZonesConfig();
    } catch (e) {
      this.AccountStore.setLoading(false);
      NotificationManager.error(e.message, "An error has occurred.");
      // }
      return [];
    }
  }

  async getUnitsConfig(assets) {
    let rates = [];

    const body = await this.wax.rpc.get_table_rows({
      json: true,
      code: this.Gamecontract,
      scope: this.Gamecontract,
      table: "udata",
      limit: 1000,
    });
    const data = body.rows;
    data.forEach((x) => {
      rates.push({
        template_id: parseInt(x.template_id),
        type: x.type,
        power: x.power,
        bonus: x.bonus,
      });
    });
    this.AccountStore.SetUConfig(rates);

    const buildings = [];
    for (let i = 0; i < assets.length; i++) {
      for (let j = 0; j < rates.length; j++) {
        let y = rates[j];
        if (parseInt(assets[i].template_id) === y.template_id) {
          assets[i].type = y.type;
          if (assets[i].type === "combat") assets[i].power = y.power;
          else assets[i].power = 0;
          assets[i].bonus = y.bonus;
          buildings.push(assets[i]);
        }
      }
    }
    this.AccountStore.setAllAssets(buildings);
    this.filterAssets(buildings);
  }

  async getZonesConfig() {
    let rates = [];
    let winData = await this.getUserWins();

    const body = await this.wax.rpc.get_table_rows({
      json: true,
      code: this.Gamecontract,
      scope: this.Gamecontract,
      table: "zones",
      limit: 1000,
    });
    const data = body.rows;
    for (let i = 0; i < data.length; i++) {
      const x = data[i];
      let winz = false;
      if (i > 0) {
        const y = data[i - 1];
        if (winData.length >= 1)
          winz =
            winData.filter((a) => parseInt(a.zone) === parseInt(y.id)).length >
            0
              ? winData.filter((a) => parseInt(a.zone) === parseInt(y.id))[0]
                  .wins >= y.nbwins
              : y.nbwins === 0;
      } else winz = true;

      rates.push({
        id: parseInt(x.id),
        name: x.name ? x.name : x.terrain + " " + x.id,
        terrain: x.terrain,
        costs: x.costs,
        resolutions: x.resolutions.sort((a, b) => a.value - b.value),
        pre_allow: x.pre_allow,
        power: x.power.sort((a, b) => a.value - b.value),
        wmini: x.wmini,
        nbwins: x.nbwins,
        nextZone: x.nextZone,
        open: x.open,
        reachable: winz,
      });
    }
    this.AccountStore.SetselectedZone(rates[0]);
    this.AccountStore.SetZConfig(rates);
    let divs = await this.getDivisions();
  }

  async getFdata() {
    const body = await this.wax.rpc.get_table_rows({
      json: true,
      code: this.Gamecontract,
      scope: this.Gamecontract,
      table: "fdata",
      limit: 1000,
    });
    const data = body.rows;
    var gg = [];
    for (let i = 0; i < data.length; i++) {
      gg.push({
        amount: data[i].fee_pool.split(" ")[0],
        symbol: data[i].fee_pool.split(" ")[1],
      });
    }
    this.AccountStore.setFdata(gg);
    return data;
  }

  async getBFdata() {
    const body = await this.wax.rpc.get_table_rows({
      json: true,
      code: this.Utilcontract,
      scope: this.Utilcontract,
      table: "fdata",
      limit: 1000,
    });
    const data = body.rows;
    var gg = [];
    for (let i = 0; i < data.length; i++) {
      gg.push({
        amount: data[i].fee_pool.split(" ")[0],
        symbol: data[i].fee_pool.split(" ")[1],
      });
    }
    this.AccountStore.setBFdata(gg);
    return data;
  }

  async getUFdata() {
    const body = await this.wax.rpc.get_table_rows({
      json: true,
      code: this.contract,
      scope: this.contract,
      table: "pools",
      limit: 1000,
    });
    const data = body.rows;
    var gg = [];
    for (let i = 0; i < data.length; i++) {
      gg.push(data);
    }
    this.AccountStore.setUFdata(gg);
    return data;
  }

  async getUserWins() {
    try {
      let balancez = [];
      const tableRows = await this.wax.rpc.get_table_rows({
        json: true,
        code: this.Gamecontract,
        scope: this.AccountStore.accountAddress,
        table: "infos",
        limit: 1000,
      });
      if (tableRows.rows.length === 0) {
        return balancez;
      } else {
        for (let i = 0; i < tableRows.rows.length; i++) {
          balancez.push(tableRows.rows[i]);
        }
        this.AccountStore.SetZoneWins(balancez);
        return balancez;
      }
    } catch (e) {}
  }

  async GetTemplate(x) {
    var final = {};
    var path =
      "/atomicassets/v1/templates/" + "dovdivisions" + "/" + x.template_id;
    const response = await fetch(this.aa + path, {
      headers: {
        "Content-Type": "text/plain",
      },
      method: "POST",
    });
    const body = await response.json();
    final = x;
    final.img = body.data.immutable_data.img;
    final.name = body.data.immutable_data.name;
    return final;
  }

  get_rtime(endtime) {
    if (endtime === 0) return -1;
    let current = new Date().getTime() / 1000;
    let tr = endtime - current;
    if (tr < 0) return 0;
    let time = new Date().getTime() + tr * 1000;
    return time;
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  async sendTransaction(actions, wallet, successMessage) {
    this.AccountStore.setLoading(true);
    try {
      if (wallet === "wcw") {
        const wax = this.wax;
        const res = await wax.api.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 30,
          }
        );
        this.AccountStore.setLoading(false);
        NotificationManager.success(successMessage);
        return res;
      } else if (wallet === "anchor") {
        const res = await this.AccountStore.getUserData()[2].transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 30,
          }
        );
        this.AccountStore.setLoading(false);
        NotificationManager.success(successMessage);
        return res.processed.id;
      }
    } catch (e) {
      this.AccountStore.setLoading(false);
      if (e.message === "assertion failure with message: Nothing to claim") {
        NotificationManager.warning("Nothing to claim.", "Warning");
      } else {
        NotificationManager.error(e.message, "An error has occurred.");
      }
    }
  }

  async getAllUnits() {
    try {
      var allDivisions = [];
      var lb = "";
      var ub = "";
      var more = true;
      let obj = [];
      while (more) {
        const body = await this.wax.rpc.get_table_rows({
          json: true,
          code: this.contract,
          scope: this.contract,
          table: "user",
          lower_bound: lb,
          limit: 1000,
        });
        if (body.rows.length !== 0) {
          for (const data of Object.values(body.rows)) {
            allDivisions.push(data);
          }
        }
        more = body.more ? more : false;
        lb = body.next_key;
      }
      allDivisions = allDivisions.filter(
        (item, index) => allDivisions.indexOf(item) === index
      );
      this.AccountStore.setAllUnits(allDivisions);
    } catch (e) {
      NotificationManager.error(e, e.message);
    }
  }
  async getAllBuildings() {
    try {
      var allDivisions = [];
      var allUsers = [];
      var lb = "";
      var ub = "";
      var more = true;
      while (more) {
        const body = await this.wax.rpc.get_table_rows({
          json: true,
          code: this.Utilcontract,
          scope: this.Utilcontract,
          table: "buildings",
          lower_bound: lb,
          limit: 1000,
        });
        if (body.rows.length !== 0) {
          for (const data of Object.values(body.rows)) {
            allDivisions.push(data);
            allUsers.push(data.owner);
          }
        }
        console.log(body);
        more = body.more ? more : false;
        lb = body.next_key;
      }
      allDivisions = allDivisions.filter(
        (item, index) => allDivisions.indexOf(item) === index
      );
      allUsers = allUsers.filter(
        (item, index) => allUsers.indexOf(item) === index
      );
      this.AccountStore.setAllBuildings(allDivisions);
      this.AccountStore.setAllUtilUsers(allUsers);

      allDivisions = allDivisions.filter((asset) => {
        var current = new Date().getTime() / 1000;
        var endt = parseInt(asset.last_claim) + parseInt(asset.delay);
        var rt = endt - current;
        if (rt <= 0) {
          asset.canClaim = true;
        } else {
          asset.canClaim = false;
        }
        return true;
      });
      const fdata2 = allDivisions.filter((a) => a.canClaim === true);
      const allBalances = await this.getAllPlayerAndBalances();
    } catch (e) {
      NotificationManager.error(e, e.message);
    }
  }
  async getPlayerBalances(player) {
    var allBalances = [];
    const body = await this.wax.rpc.get_table_rows({
      json: true,
      code: this.Utilcontract,
      scope: player,
      table: "balances",
      limit: 100,
    });
    if (body.rows.length !== 0) {
      for (const data of Object.values(body.rows)) {
        var symbol = data.balance.split(" ")[1];
        if (symbol === "AVT" || symbol === "ADMIN") continue;
        var amount = parseFloat(data.balance.split(" ")[0]);
        allBalances.push({ symbol: symbol, amount: amount });
      }
    }
    return allBalances;
  }

  async getPlayerWBalances(player) {
    var allBalances = [];
    const body = await this.wax.rpc.get_table_rows({
      json: true,
      code: this.tokenContract,
      scope: player,
      table: "accounts",
      limit: 100,
    });
    if (body.rows.length !== 0) {
      for (const data of Object.values(body.rows)) {
        var symbol = data.balance.split(" ")[1];
        if (symbol === "AVT" || symbol === "ADMIN") continue;
        var amount = parseFloat(data.balance.split(" ")[0]);
        allBalances.push({ symbol: symbol, amount: amount });
      }
    }
    return allBalances;
  }

  async getAllPlayerAndBalances() {
    //get all valid scopes from the contract accounts table
    var more = true;
    var lb = "";
    var ub = "";
    var playerx = [];
    while (more) {
      var body = await this.wax.rpc.get_table_by_scope({
        code: this.Utilcontract,
        table: "balances",
        json: true,
        lower_bound: lb,
        limit: 1000,
      });
      more = body.more !== "" ? more : false;
      lb = body.more;
      for (const data of Object.values(body.rows)) {
        playerx.push(data.scope);
      }
    }
    var allBalances = this.getAllBalances(playerx);
    return playerx;
  }

  async getAllBalances(players) {
    var allBalances = this.AccountStore.allBalances.slice();
    var WBalances = this.AccountStore.walletBalances.slice();
    var allPlayerBalances = [];
    for (let i = 0; i < players.length; i++) {
      const playerBalance = await this.getPlayerBalances(players[i]);
      const playerWBalance = await this.getPlayerWBalances(players[i]);
      //Combine both arrays by comparing symbol, if found add amounts
      const finalArrayBalance = [];
      for (const data of Object.values(playerBalance)) {
        const symbol = data.symbol;
        const amount = parseFloat(data.amount);
        var index = playerWBalance.findIndex((a) => a.symbol === symbol);
        if (index === -1) {
          finalArrayBalance.push({ symbol: symbol, amount: amount });
        } else {
          finalArrayBalance.push({
            symbol: symbol,
            amount: amount + playerWBalance[index].amount,
          });
        }
      }

      allPlayerBalances.push({
        player: players[i],
        balances: finalArrayBalance,
      });
      if (playerBalance.length !== 0) {
        for (const data of Object.values(playerBalance)) {
          const symbol = data.symbol;
          const amount = parseFloat(data.amount);
          var index = allBalances.findIndex((a) => a.symbol === symbol);
          if (index === -1) {
            allBalances.push({ symbol: symbol, amount: amount });
          } else {
            allBalances[index].amount += amount;
          }
        }
        this.AccountStore.setAllBalances(allBalances);
      }
      if (playerWBalance.length !== 0) {
        for (const data of Object.values(playerWBalance)) {
          const symbol = data.symbol;
          const amount = parseFloat(data.amount);
          var index = WBalances.findIndex((a) => a.symbol === symbol);
          if (index === -1) {
            WBalances.push({ symbol: symbol, amount: amount });
          } else {
            WBalances[index].amount += amount;
          }
        }
        this.AccountStore.setAllWalletBalances(WBalances);
      }

      allPlayerBalances = allPlayerBalances.sort((a, b) => {
        var aTotal = 0;
        var bTotal = 0;
        for (let i = 0; i < a.balances.length; i++) {
          aTotal += a.balances[i].amount;
        }
        for (let i = 0; i < b.balances.length; i++) {
          bTotal += b.balances[i].amount;
        }
        return bTotal - aTotal;
      });
      this.AccountStore.setAllUsers(allPlayerBalances);
    }
    this.AccountStore.setAllBalances(allBalances);
    this.AccountStore.setAllUsers(allPlayerBalances);
  }

  async getWinsScope() {
    try {
      var allDivisions = [];
      var lb = "";
      var ub = "";
      var more = true;
      let obj = [];
      while (more) {
        const body = await this.wax.rpc.get_table_rows({
          json: true,
          code: this.Gamecontract,
          scope: this.Gamecontract,
          table: "sdivisions",
          lower_bound: lb,
          limit: 1000,
        });
        if (body.rows.length !== 0) {
          for (const data of Object.values(body.rows)) {
            obj.push(data.owner);
            allDivisions.push(data);
          }
        }
        more = body.more;
        lb = body.next_key;
      }
      allDivisions.filter(
        (item, index) => allDivisions.indexOf(item) === index
      );
      this.AccountStore.setAllDivisions(allDivisions);
      obj.filter((item, index) => obj.indexOf(item) === index);
      //let dataa= await this.getBalanceBoards(obj);
      //this.AccountStore.setWinsScope(dataa);
      return obj;
    } catch (e) {
      NotificationManager.error(e, e.message);
    }
  }

  async getBalanceBoards(scopes) {
    try {
      scopes.filter((item, index) => scopes.indexOf(item) === index);
      var lb = "";
      var ub = "";
      var more = true;
      let BalancesArray = [];
      let max = scopes.length - 1;
      while (max >= 0) {
        let obj = scopes[max];
        let zar = [];
        const body = await this.wax.rpc.get_table_rows({
          json: true,
          code: this.Gamecontract,
          scope: obj,
          table: "infos",
          limit: 1000,
        });
        if (body.rows.length !== 0) {
          for (const data of Object.values(body.rows)) {
            zar.push(data);
          }
        }
        var newUser = {};
        newUser.name = obj;
        newUser.balances = zar;
        max -= 1;
        BalancesArray.push(newUser);
      }
      return BalancesArray;
    } catch (e) {
      NotificationManager.error(e, e.message);
    }
  }
}
