import "./index.sass";
import "../../main.css";
import "./burger-styles.sass";
import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import { NavLink, useLocation } from "react-router-dom";
import ChooseWalletModal from "../chooseWalletModal";
import { useEffect, useState, useContext } from "react";
import { ReactComponent as WalletUserIcon } from "../../assets/icons/wallet-user-icon.svg";
import { slide as Menu } from "react-burger-menu";
import LoginHandler from "../../functions/login";
import { Sidenav, Nav, Dropdown } from "rsuite";
import Arrow from "../../components/hover";
import { UALContext } from "ual-reactjs-renderer";

function Header() {
  const { AccountStore } = StoreContext();
  const [modalOpened, setModalOpened] = useState(false);
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [logOutOpened, setLogOutOpened] = useState(false);
  const [hoverState, sethoverState] = useState([0, 0, 0, 0]);
  const { pathname } = useLocation();
  const loginHandler = new LoginHandler();
  const ual = useContext(UALContext);

  useEffect(() => {
    if (ual.activeUser) loginHandler.login(ual.activeUser);
  });

  const login = () => {
    if (ual.activeUser) loginHandler.login(ual.activeUser);
    else {
      ual.logout();
      ual.showModal();
    }
  };

  return (
    <div>
      <Sidenav defaultOpenKeys={["3", "4"]} activeKey="1">
        <Sidenav.Body>
          <div className="menu">
            <div id="avatar">
              <a href="./avatars">
                <img
                  src={`https://dov-gw.mypinata.cloud/ipfs/${
                    AccountStore.getAvatarIndex().ipfs
                  }`}
                  alt=""
                  className="imgavatar"
                  onClick={(e) => {}}
                />
              </a>
            </div>
            <div id="account_block">
              <span id="account_name">{AccountStore.accountAddress}</span>
              {AccountStore.accountAddress ? (
                <div
                  className="header-wallet"
                  onClick={() => setLogOutOpened(true)}
                >
                  <img className="header-wallet-icon" />
                  <div
                    className={`header-wallet-logout-wrapper ${
                      logOutOpened ? "header-wallet-logout-wrapper-active" : ""
                    }`}
                  >
                    <div
                      className="header-wallet-logout-cross"
                      onClick={(e) => {
                        e.stopPropagation();
                        setLogOutOpened(false);
                      }}
                    >
                      <div className="header-wallet-logout-cross-line" />
                      <div className="header-wallet-logout-cross-line" />
                    </div>
                    <button
                      className="header-wallet-logout"
                      onClick={(e) => {
                        ual.logout();
                        e.stopPropagation();
                        setLogOutOpened(false);
                        AccountStore.setUserData([]);
                        AccountStore.changeAccountAddress(null);
                      }}
                    >
                      Log out
                    </button>
                  </div>
                </div>
              ) : (
                <button className="header-log-in" onClick={() => login()}>
                  Log In
                </button>
              )}
              <ChooseWalletModal
                opened={modalOpened}
                setOpened={setModalOpened}
              />
            </div>
            <div id="menuMissions" className="btn">
              <a href="utils">Unit Staking</a>
            </div>
            <div id="menuMissions" className="btn">
              <a href="building">Building Staking</a>
            </div>
            <div id="menuMissions" className="btn">
              <a href="pve">PVE</a>
            </div>
            <div id="menuMissions" className="btn">
              <a href="Balances">All Balances</a>
            </div>
            <div id="menuMissions" className="btn">
              <a href="PlayerPVE">Player_PVE</a>
            </div>
            <div id="menuMissions" className="btn">
              <a href="PlayerBuildings">Player_Buildings</a>
            </div>
          </div>
        </Sidenav.Body>
      </Sidenav>
    </div>
  );
}

export default observer(Header);

//0.6498
