import "./index.sass";
import "../../main.css";
import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import * as waxjs from "@waxio/waxjs/dist";
import { useEffect, useState } from "react";
import eosjsName from "eosjs-account-name";
import Pagination from "../../components/pagination";
import Notification from "../../components/notification";
import { NotificationManager } from "react-notifications";
import Preloader from "../../components/preloader";
import { Int64 } from "anchor-link";
import MyTimer from "../../components/timer";
import LoginHandler from "../../functions/login";
import { BrowserRouter as Router, Link } from "react-router-dom";

function HOF() {
  const { AccountStore } = StoreContext();
  const [Zconfig, SetZConfig] = useState([]);
  const [DailyCosts, setDailyCosts] = useState([
   {key:"DOVX",value:0},
   {key:"DOVR",value:0},
   {key:"DOVS",value:0},
   {key:"DOVF",value:0},
   {key:"DOVH",value:0},
  ]);
  const [DailyReward, setReward] = useState([]);

  const loginHandler = new LoginHandler();

  useEffect(() => {
    if (AccountStore.accountAddress) {
      getZonesConfig();
      loginHandler.getFdata();
      }
  }, [AccountStore.accountAddress]);


  async function getZonesConfig() {
   let rates = [];
   var winsScope=await loginHandler.getWinsScope();
   const tableRows = await loginHandler.wax.rpc.get_table_rows({
     json: true,
     code: loginHandler.Gamecontract,
     scope: loginHandler.Gamecontract,
     table: "zones",
     limit: 1000,
   });
   const data = tableRows.rows;
   for (let i = 0; i < data.length; i++) {
     const x = data[i];
     rates.push({
       id: parseInt(x.id),
       name: x.name ? x.name : x.terrain + " " + x.id,
       terrain: x.terrain,
       costs: x.costs,
       resolutions: x.resolutions.sort((a, b) => a.value - b.value),
       pre_allow: x.pre_allow,
       power: x.power.sort((a, b) => a.value - b.value),
       wmini: x.wmini,
       nbwins: x.nbwins,
       nextZone: x.nextZone,
       open: x.open,
     });
     var ratesCopy=rates[i];
     ratesCopy.allWinners=getAllWiners(ratesCopy);
   }
   console.log(rates);
   var daily= getDaily(rates);
   setReward(daily);
   SetZConfig(rates);
 } 

function filterOwners()
{
var fdata=AccountStore.allDivisions.slice();
var filteredDivz=[];
for(var i = 0; i < fdata.length; i++)
   {
   if(fdata[i].owner)
   {
   filteredDivz.push(fdata[i].owner);
   }
   }
   //Remove duplicate
   filteredDivz = filteredDivz.filter((v, i, a) => a.indexOf(v) === i);
   return filteredDivz;
}
function getDaily(rates)
{
var finalWin=0;
var finalLoss= 0;
var finalDraw= 0;
for(var i = 0; i < rates.length; i++)
{
   var zone= rates[i];
   var allWinners= zone.allWinners;
   if(allWinners)
   {
   var winnerCosts=allWinners.wincosts;
   if(winnerCosts)
   {
      finalWin+=winnerCosts;
   }
   var losscosts= allWinners.losscosts;
   if(losscosts)
   {
      finalLoss+=losscosts;
   }
   var drawcosts= allWinners.drawcosts;
   if(drawcosts)
   {
      finalDraw+=drawcosts;
   }

}
}
var final= [finalWin,finalDraw,finalLoss];
var finalz=[
   {key:"win",value:final[0]},
   {key:"draw",value:final[1]},
   {key:"loss",value:final[2]},
];
return finalz;
}

function getAllWiners(zone)
{
var allWins=AccountStore.allDivisions.filter(a=>loginHandler.get_rtime(a.end_time)>0).slice();
var filteredWinners=[];
var filteredDrawers=[];
var filteredLosers=[];
var filteredWinnerCosts=0;
var filteredDrawCosts=0;
var filteredLoserCosts=0;
var dailyCosts=DailyCosts.slice();
for(var i = 0; i < allWins.length; i++)
{
if(allWins[i].zoneID===zone.id)
{
var win= parseFloat(zone.power.filter(a=>a.key==="win")[0].value);
var loss= parseFloat(zone.power.filter(a=>a.key==="loss")[0].value);
var draw= parseFloat(zone.power.filter(a=>a.key==="draw")[0].value);
var winCost= parseFloat(zone.resolutions.filter(a=>a.key==="win")[0].value);
var lossCost= parseFloat(zone.resolutions.filter(a=>a.key==="loss")[0].value);
var drawCost= parseFloat(zone.resolutions.filter(a=>a.key==="draw")[0].value);
allWins[i].power= parseFloat(allWins[i].power);
if(allWins[i].power>=loss && allWins[i].power<draw)
{
   filteredLoserCosts+=lossCost;
   filteredLosers.push(allWins[i]);
}
else if(allWins[i].power>=draw && allWins[i].power<win)
{
   filteredDrawCosts+=drawCost;
   filteredDrawers.push(allWins[i]);
}
else if(allWins[i].power>=win)
{
   filteredWinnerCosts+=winCost;
   filteredWinners.push(allWins[i]);
}
for(var j = 0; j < zone.costs.length; j++)
{
var cost= zone.costs[j];
var costKey=cost.key;
var costValue=parseFloat(cost.value);
for(var k = 0; k < dailyCosts.length; k++)
{
var dailyCost= dailyCosts[k];
var dailyKey=dailyCost.key;
if(costKey===dailyKey)
{
var dailyValue=dailyCost.value;
dailyValue+=costValue;
dailyCost.value=dailyValue;
}
}
}
}
}
setDailyCosts(dailyCosts);
var final= {
   winners: filteredWinners,
   wincosts: filteredWinnerCosts,
   drawers: filteredDrawers,
   drawcosts: filteredDrawCosts,
   losers: filteredLosers,
   losscosts: filteredLoserCosts,
};
return final;
}

  return (
        <div className="parent">
            <div>
                <div id="topStats">
                  <div class="arrow"></div>
                  <div class="topTitle"><span>Divisions fightning PVE</span></div>
                </div>
            </div>
            <div class="clearfix"></div>
            <div class="left50">
                <div id="tableStats" class="tableHoF w90">
                  <table>
                    <tbody>
                    <tr>
                        <td><h2>Accounts</h2></td>
                        <td><span>{filterOwners().length}</span></td>
                    </tr>
                    <tr>
                      <td><h2>Divisions</h2></td>
                      <td><span>{AccountStore.allDivisions.length}</span></td>
                    </tr>
                    <tr>
                      <td><h2>In Fight</h2></td>
                      <td><span>{AccountStore.allDivisions.filter(a=>loginHandler.get_rtime(a.end_time)>0).length}</span></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
            <div class="clearfix mgb15"></div>
            <div id="ArmedTable" class="tableHoF">
                <table>
                    <tbody>
                    <th colspan="2">Total Consumption</th>
                    {AccountStore.fdata.map((asset, index) => {
                        return (
                            <tr>
                                <td><span>{asset.symbol}</span></td>
                                <td><span>{asset.amount}</span></td>
                            </tr>
                        )})}
                    </tbody>
                </table>
            </div>
            <div class="clearfix mgb15"></div>
            <div id="ArmedTable" class="tableHoF">
                <table>
                    <tbody>
                    <th colspan="2">Daily Reward (Est)</th>
                    {DailyReward.map((asset, index) => {
                        return (
                            <tr>
                                <td><span class="capitalized">{asset.key}</span></td>
                                <td><span>{parseFloat(asset.value*4/10000).toFixed(4)}</span></td>
                            </tr>
                        )})}
                    </tbody>
                </table>
            </div>
            <div class="clearfix mgb15"></div>
            <div id="ArmedTable" class="tableHoF">
                <table>
                    <tbody>
                    <th colspan="2">Daily Consumption (Est)</th>
                    {DailyCosts.map((asset, index) => {
                        return (
                            <tr>
                                <td><span>{asset.key}</span></td>
                                <td><span>{parseFloat((asset.value*4)/10000).toFixed(4)}</span></td>
                            </tr>
                        )})}
                    </tbody>
                </table>
            </div>
        </div>
            <div class="right50">
                    <div class="tableHoF tableDFPVE">
                        <table>
                            <tbody>
                                <tr>
                                    <th><span>#</span></th>
                                    <th><span>Zone</span></th>
                                    <th><span>Saved</span></th>
                                    <th><span>In fight</span></th>
                                    <th><span>Claimable</span></th>
                                    <th><span>Idle</span></th>
                                    <th><span>Win (# / DOVX)</span></th>
                                    <th><span>Draw (# / DOVX)</span></th>
                                    <th><span>Loss (# / DOVX)</span></th>
                                </tr>
                            {Zconfig.map((zone, index) => {
                                return (
                                    <tr>
                                        <td><span>{index+1}</span></td>
                                        <td><span>{zone.name}</span></td>
                                        <td><span>{AccountStore.allDivisions.filter(a=>(a.zoneID===zone.id)).length}</span></td>
                                        <td><span>{AccountStore.allDivisions.filter(a=> (a.zoneID===zone.id && loginHandler.get_rtime(a.end_time)>0)).length}</span></td>
                                        <td><span>{AccountStore.allDivisions.filter(a=> (a.zoneID===zone.id && loginHandler.get_rtime(a.end_time)==0)).length}</span></td>
                                        <td><span>{AccountStore.allDivisions.filter(a=> (a.zoneID===zone.id && loginHandler.get_rtime(a.end_time)==-1)).length}</span></td>
                                        <td><span>{zone.allWinners.winners.length+" "} / {parseFloat(zone.allWinners.wincosts/10000).toFixed(0)+" "}</span></td>
                                        <td><span>{zone.allWinners.drawers.length+" "} / {parseFloat(zone.allWinners.drawcosts/10000).toFixed(0)+" "}</span></td>
                                        <td><span>{zone.allWinners.losers.length+" "} / {parseFloat(zone.allWinners.losscosts/10000).toFixed(0)+" "}</span></td>
                                    </tr>
                                )})}
                            </tbody>
                        </table>
                    </div>
            </div>
        </div>
  );
}

export default observer(HOF);
