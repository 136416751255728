import "./index.sass";
import "../../main.css";
import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import * as waxjs from "@waxio/waxjs/dist";
import { useEffect, useState } from "react";
import LoginHandler from "../../functions/login";
import { BrowserRouter as Router, Link } from "react-router-dom";
import eosjsName from "eosjs-account-name";

function Player_PVE() {
  const { AccountStore } = StoreContext();
  const [Account, setAccount] = useState("");
  const [Input, setInput] = useState("");
  const [Buildings, setBuildings] = useState([]);
  const [Zconfig, SetZConfig] = useState([]);

  const loginHandler = new LoginHandler();

  useEffect(() => {
    if (AccountStore.accountAddress) {
      getZonesConfig();
    if (Account!=="") {
      GetBuildings();
      }
    }
  }, [AccountStore.accountAddress]);


  async function getZonesConfig() {
    let rates = [];
    const tableRows = await loginHandler.wax.rpc.get_table_rows({
      json: true,
      code: loginHandler.Gamecontract,
      scope: loginHandler.Gamecontract,
      table: "zones",
      limit: 1000,
    });
    const data = tableRows.rows;
    for (let i = 0; i < data.length; i++) {
      const x = data[i];
      rates.push({
        id: parseInt(x.id),
        name: x.name ? x.name : x.terrain + " " + x.id,
        terrain: x.terrain,
        costs: x.costs,
        resolutions: x.resolutions.sort((a, b) => a.value - b.value),
        pre_allow: x.pre_allow,
        power: x.power.sort((a, b) => a.value - b.value),
        wmini: x.wmini,
        nbwins: x.nbwins,
        nextZone: x.nextZone,
        open: x.open,
      });
    }
    console.log(rates);
    SetZConfig(rates);
  } 
  async function GetTemplate (x){
    var final={};
        var path = "/atomicassets/v1/templates/" + "dovdivisions" + "/" +x.template_id;
        const response = await fetch(loginHandler.aa + path, {
            headers: {
                "Content-Type": "text/plain"
            },
            method: "POST",
        });
        const body = await response.json();
        final=
            {
                template_id : x.template_id,
                type: x.type,
                costs: x.costs,
                schema:x.schema,
                available:x.available,
                img: body.data.immutable_data.img,
                name: body.data.immutable_data.name
            };
            return final;
    }

async function GetBuildings(userName) {
  try {
    if(!AccountStore.accountAddress) return;
    var zones= await getZonesConfig();
    var result=[];
    const tableRows = await loginHandler.wax.rpc.get_table_rows({
      json: true,
      code: loginHandler.Utilcontract,
      scope: loginHandler.Utilcontract,
      table: "buildings",
      key_type: "i64",
      index_position: 2,
      lower_bound: eosjsName.nameToUint64(userName),
      upper_bound: eosjsName.nameToUint64(userName),
      limit: 1000,
    });
    if (tableRows.rows.length === 0) {
    } else {
      for (let i = 0; i < tableRows.rows.length; i++) {
        var row=tableRows.rows[i];
        if(row.owner===userName)
        {
          var Zconfi=Zconfig.slice();
          if(Zconfi.length>0)
          {
            const zoneID= row.zone_id;
            var Zone= Zconfi.filter((z) => z.id === zoneID);
            if(Zone.length>0)
            {
              var ZoneName=Zone[0].name;
              row.name= ZoneName;
            }
          }
          var current = new Date().getTime() / 1000;
          var endt = parseInt(row.last_claim) + parseInt(row.delay);
          var rt = endt - current;
          if (rt <= 0) row.claim = true;
          else row.claim = false;
          result.push(row);
        }
        }
      }
      console.log(result);
      const promises=await sort(result);
      const responses = await Promise.all(promises);
      setBuildings(responses);
  } catch (e) {}
}
async function sort(dd)
{
    const promisesToAwait = [];
    for (let i = 0; i < dd.length; i++) {
      promisesToAwait.push(loginHandler.GetTemplate(dd[i]));
    }
  return promisesToAwait;
}


function getStatus(division)
{
  if(division.claim)
  {
    return "Ready To Claim";
  }
  else 
  {
    return "In Progress ";
  }
}

function updateInputValue(e) {
  const val = e.target.value;
  setInput(val);
}
  return (
        <div className="parent">
        <div className="pdb25">
            <div class="topDesc">
                <button className="header-wallet-logout" onClick={(e)=> {
                    GetBuildings(Input);
                }}>Load</button>
                <input value={Input} onChange={evt => updateInputValue(evt)}/>
            </div>
        </div>
            <div class="clearfix"></div>
            <div>
                           {
                              Buildings.map((item, index) => {
                                return (  
                                  <>
                        <div key={index} class="left25 tableHoF w100p5">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th colspan="3"><span>{item.name}</span></th>
                                                </tr>
                                                <tr>
                                                    <td><img src={`https://dov-gw.mypinata.cloud/ipfs/${item.img}`} alt="" className="img-bz"/></td>
                                                    <td><span>{item.asset_id}</span></td>
                                                    <td><span>{getStatus(item)}</span></td>
                                                </tr>
                                                <tr>
                                                    <th colspan="3"><span>Consumption</span></th>
                                                </tr>
                                                <tr>
                                                    {
                                                    item.token_in.map((item, index) => {
                                                    return (
                                                        <td class="bluetd"><span>{item}</span></td>
                                                    )})
                                                    }
                                                </tr>
                                                <tr>
                                                    <th colspan="3"><span>Production</span></th>
                                                </tr>
                                                <tr>
                                                  {
                                                    item.power.map((item, index) => {
                                                    return (
                                                        <td class="bluetd" colspan="3"><span>{item}</span></td>
                                                    )})
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>
                                          </div>
                                          </>
                              )})
                            }
                                          </div>
                           </div>
  );
}

export default observer(Player_PVE);
