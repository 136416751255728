import "./index.sass";
import "../../main.css";
import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import * as waxjs from "@waxio/waxjs/dist";
import { useEffect, useState } from "react";
import LoginHandler from "../../functions/login";
import { BrowserRouter as Router, Link } from "react-router-dom";

function HOF() {
  const { AccountStore } = StoreContext();
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState({
    dovx: "0.00",
    dovr: "0.00",
    dovs: "0.00",
    dovf: "0.00",
    dovh: "0.00",
  });

  const loginHandler = new LoginHandler();

  useEffect(() => {
    if (AccountStore.accountAddress) {
      loginHandler.getAllUnits();
      loginHandler.getUFdata();
      getContractDovBalance();
      }
  }, [AccountStore.accountAddress]);

function filterUnits()
{
var fdata=AccountStore.allUnits.slice();
return fdata;
}
function filterDivisions()
{
var zdata=AccountStore.ufdata.slice();
for(var i=0;i<zdata.length;i++)
{
const fdata=zdata[i][0];
var nfts= fdata.total_nfts;
var daily= fdata.daily_pool;
return [nfts,daily];
}
return [0,0];
}


async function getContractDovBalance() {
  try {
    let balancez={
      dovx: "0.00",
      dovr: "0.00",
      dovs: "0.00",
      dovf: "0.00",
      dovh: "0.00",
    };
    const tableRows = await loginHandler.wax.rpc.get_table_rows({
      json: true,
      code: loginHandler.tokenContract,
      scope: loginHandler.contract,
      table: "accounts",
      limit: 1000,
    });
    if (tableRows.rows.length === 0) {
    } else {
      for (let i = 0; i < tableRows.rows.length; i++) {
        if (tableRows.rows[i].balance.includes("DOVX")) {
          balancez.dovx = parseFloat(tableRows.rows[i].balance);
        } else if (tableRows.rows[i].balance.includes("DOVR")) {
          balancez.dovr = parseFloat(tableRows.rows[i].balance);
        } else if (tableRows.rows[i].balance.includes("DOVS")) {
          balancez.dovs = parseFloat(tableRows.rows[i].balance);
        } else if (tableRows.rows[i].balance.includes("DOVF")) {
          balancez.dovf = parseFloat(tableRows.rows[i].balance);
        } else if (tableRows.rows[i].balance.includes("DOVH")) {
          balancez.dovh = parseFloat(tableRows.rows[i].balance);
        }
      }
      console.log(balancez);
      setBalance(balancez);
    }
  } catch (e) {}
}

  return (
        <div className="parent">
          <div class="left50">
            <div id="topStats">
              <div class="arrow"></div>
              <div class="topTitle"><span>UNIT STAKING</span></div>
            </div>
            <div id="tableStats" class="tableHoF">
              <table>
                <tbody>
                <tr>
                  <td><h2>Total Accounts</h2></td>
                  <td><span>{filterUnits().length}</span></td>
                </tr>
                <tr>
                  <td><h2>Total Units</h2></td>
                  <td><span>{filterDivisions()[0]}</span></td>
                </tr>
                <tr>
                  <td><h2>Daily Reward Pool</h2></td>
                  <td><span>{filterDivisions()[1]}</span></td>
                </tr>
                <tr>
                  <td><h2>To Distribute</h2></td>
                  <td><span>{balance.dovx+" DOVX"}</span></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
  );
}

export default observer(HOF);
