import "./index.sass";
import "../../main.css";
import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import * as waxjs from "@waxio/waxjs/dist";
import { useEffect, useState } from "react";
import LoginHandler from "../../functions/login";
import { BrowserRouter as Router, Link } from "react-router-dom";

function Building() {
  const { AccountStore } = StoreContext();
  const [loading, setLoading] = useState(false);
  const [craftData, setCraftData] = useState([]);
  const loginHandler = new LoginHandler();

  useEffect(() => {
    if (AccountStore.accountAddress) {
      loginHandler.getAllBuildings();
      loginHandler.getBFdata();
      getCraftData();
      }
  }, [AccountStore.accountAddress]);

function filterUnits()
{
var fdata=AccountStore.allBuildings.slice();
return fdata;
}

function filterUnitsInProd()
{
var fdata=AccountStore.allBuildings.slice();
const fdata2= fdata.filter(
  (asset)=>
  {
    var current = new Date().getTime() / 1000;
    var endt = parseInt(asset.last_claim) + parseInt(asset.delay);
    if(current<endt)
    {
      return true;
    }
    else
    {
      return false;
    }
  }
);
return fdata2;
}

function getDailyConsumption()
{
  var totalBalance=[
    {amount:0,symbol:"DOVX"},
    {amount:0,symbol:"DOVR"},
    {amount:0,symbol:"DOVS"},
    {amount:0,symbol:"DOVF"},
    {amount:0,symbol:"DOVH"},
  ];
 
  var inProdx= filterUnitsInProd();
  for(var i=0;i<inProdx.length;i++)
  {
    var inProd= inProdx[i];
    for(var j=0;j<inProd.token_in.length;j++)
    {
    var consume= {amount:parseFloat(inProd.token_in[j]),symbol:inProd.token_in[j].split(" ")[1]};
    for(var k=0;k<totalBalance.length;k++)
    {
        if(totalBalance[k].symbol===consume.symbol)
        {
          totalBalance[k].amount+=parseFloat(consume.amount);
        }
    }
  }
}
  return totalBalance;
}

function getDailyProduction()
{
  var totalBalance=[
    {amount:0,symbol:"DOVX"},
    {amount:0,symbol:"DOVR"},
    {amount:0,symbol:"DOVS"},
    {amount:0,symbol:"DOVF"},
    {amount:0,symbol:"DOVH"},
  ];
 
  var inProdx= filterUnitsInProd();
  for(var i=0;i<inProdx.length;i++)
  {
    var inProd= inProdx[i];
    for(var j=0;j<inProd.power.length;j++)
    {
    var power= {amount:parseFloat(inProd.power[j]),symbol:inProd.power[j].split(" ")[1]};
    for(var j=0;j<totalBalance.length;j++)
    {
        if(totalBalance[j].symbol===power.symbol)
        {
          totalBalance[j].amount+=parseFloat(power.amount);
          break;
        }
    }
  }
  }
  return totalBalance;
}

function filterAccounts()
{
var fdata=AccountStore.allBUsers.slice();
return fdata;
}

async function getCraftData() {
  try {
    let craftData=[];
    const tableRows = await loginHandler.wax.rpc.get_table_rows({
      json: true,
      code: loginHandler.Utilcontract,
      scope: loginHandler.Utilcontract,
      table: "configs",
      limit: 1000,
    });
    if (tableRows.rows.length === 0) {
    } else {
      var craftz = tableRows.rows[0];
      craftData = [{amount:craftz.DOVF_fee,symbol:"DOVF"},
      {amount:craftz.DOVH_fee,symbol:"DOVH"},
      {amount:craftz.DOVS_fee,symbol:"DOVS"},
      {amount:craftz.DOVR_fee,symbol:"DOVR"},
      {amount:craftz.DOVX_fee,symbol:"DOVX"}];
      console.log(craftData);
      setCraftData(craftData);
    }
  } catch (e) {}
}

  return (
        <div className="parent">

            <div class="left50">
                <div id="topStats">
                  <div class="arrow"></div>
                  <div class="topTitle"><span>BUILDING STAKING</span></div>
                </div>
                <div id="tableStats" class="tableHoF">
                  <table>
                    <tbody>
                    <tr>
                      <td><h2>Total Accounts</h2></td>
                      <td><span>{filterAccounts().length}</span></td>
                    </tr>
                    <tr>
                      <td><h2>Total Buildings</h2></td>
                      <td><span>{filterUnits().length }</span></td>
                    </tr>
                    <tr>
                      <td><h2>In Prod</h2></td>
                      <td><span>{filterUnitsInProd().length
                    +"  ("+ parseFloat(filterUnitsInProd().length*100/filterUnits().length).toFixed(4) +"%)"
                    }</span></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            <div class="clearfix mgb15"></div>
            <div class="left25">
                <div id="ArmedTable" class="tableHoF">
                <table>
                    <tbody>
                    <th colspan="2">Total Consumption</th> {AccountStore.bfdata.map((asset, index) => {
                        return (
                            <tr>
                                <td><span>{asset.symbol}</span></td>
                                <td><span>{asset.amount}</span></td>
                            </tr>
                        )})}
                    </tbody>
                </table>
            </div>
            </div>
            <div class="left25">
                <div id="ArmedTable" class="tableHoF">
                <table>
                    <tbody>
                    <th colspan="2">Total Craft</th> {craftData.map((asset, index) => {
                        return (
                            <tr>
                                <td><span>{asset.symbol}</span></td>
                                <td><span>{parseFloat(asset.amount/10000).toFixed(4)}</span></td>
                            </tr>
                        )})}
                    </tbody>
                </table>
            </div>
            </div>
            <div class="left25">
                <div id="ArmedTable" class="tableHoF">
                <table>
                    <tbody>
                    <th colspan="2">Daily Production</th> {getDailyProduction().map((asset, index) => {
                        return (
                            <tr>
                                <td><span>{asset.symbol}</span></td>
                                <td><span>{parseFloat(asset.amount*24).toFixed(4)}</span></td>
                            </tr>
                        )})}
                    </tbody>
                </table>
            </div>
            </div>
            <div class="left25">
                <div id="ArmedTable" class="tableHoF">
                <table>
                    <tbody>
                    <th colspan="2">Daily Consumption</th> {getDailyConsumption().map((asset, index) => {
                        return (
                            <tr>
                                <td><span>{asset.symbol}</span></td>
                                <td><span>{parseFloat(asset.amount*24).toFixed(4)}</span></td>
                            </tr>
                        )})}
                    </tbody>
                </table>
            </div>
            </div>
        </div>
  );
}

export default observer(Building);
