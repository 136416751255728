import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import App from './App'
import Page404 from "./pages/404";
import Units from "./pages/utils";
import Building from "./pages/building";
import HOFPage from "./pages/hof";
import PlayerPVE from "./pages/PPVE";
import PlayerBuildings from "./pages/PBUILD";
import Balances from "./pages/allBalances";
import TokenCounter from "./components/tcounter";

import Header from "./components/header";
import "./index.sass";
import "./main.css";

export default function AppRoutes() {
    return (
        <App>
            <Router>
        <div className='wrapper'>
        <Header/>
        <div className='content-wrapper'>
        <TokenCounter/>
                    <Routes>
                        <Route path="/pve" element={<HOFPage/>}/>
                        <Route path="/utils" element={<Units/>}/>
                        <Route path="/building" element={<Building/>}/>
                        <Route path="/PlayerPVE" element={<PlayerPVE/>}/>
                        <Route path="/Balances" element={<Balances/>}/>
                        <Route path="/PlayerBuildings" element={<PlayerBuildings/>}/>
                        <Route path="*" element={<Page404/>}/>
                    </Routes>
                </div>
                </div>
            </Router>
        </App>
        );
}
