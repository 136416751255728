import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { UALProvider, withUAL } from "ual-reactjs-renderer";
import { Wax } from "@eosdacio/ual-wax";
import { Anchor } from "ual-anchor";
import { Wombat } from "wombat-ual";
import AppRoutes from "./routes";
import "./index.sass";

const root = ReactDOM.createRoot(document.getElementById("root"));
const myChain = {
  chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
  rpcEndpoints: [
    {
      protocol: "https",
      host: "wax.api.eosnation.io",
      port: "",
    },
  ],
};

const wax = new Wax([myChain], { appName: "DOVCX" });
const anchor = new Anchor([myChain], {
  appName: "DOVCX",
});
const wombat = new Wombat([myChain], { appName: "DOVCX" });
const MyUALConsumer = withUAL(App);

root.render(
  <UALProvider
    chains={[myChain]}
    authenticators={[wax, anchor, wombat]}
    appName={"DOVCX"}
  >
    <MyUALConsumer />
    <AppRoutes />
  </UALProvider>
);
