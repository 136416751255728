import "./index.sass";
import "../../main.css";
import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import * as waxjs from "@waxio/waxjs/dist";
import { useEffect, useState } from "react";
import LoginHandler from "../../functions/login";
import { BrowserRouter as Router, Link } from "react-router-dom";
import eosjsName from "eosjs-account-name";

function Player_PVE() {
  const { AccountStore } = StoreContext();
  const [Account, setAccount] = useState("");
  const [Input, setInput] = useState("");
  const [Divisions, setDivisions] = useState([]);
  const [Zconfig, SetZConfig] = useState([]);
  const [blc, setBlc] = useState([]);
  const [Wblc, setWBlc] = useState([]);

  const loginHandler = new LoginHandler();

  useEffect(() => {
    if (AccountStore.accountAddress) {
      getZonesConfig();
    if (Account!=="") {
      GetDivisions();
      }
    }
  }, [AccountStore.accountAddress]);


  async function getZonesConfig() {
    let rates = [];
    const tableRows = await loginHandler.wax.rpc.get_table_rows({
      json: true,
      code: loginHandler.Gamecontract,
      scope: loginHandler.Gamecontract,
      table: "zones",
      limit: 1000,
    });
    const data = tableRows.rows;
    for (let i = 0; i < data.length; i++) {
      const x = data[i];
      rates.push({
        id: parseInt(x.id),
        name: x.name ? x.name : x.terrain + " " + x.id,
        terrain: x.terrain,
        costs: x.costs,
        resolutions: x.resolutions.sort((a, b) => a.value - b.value),
        pre_allow: x.pre_allow,
        power: x.power.sort((a, b) => a.value - b.value),
        wmini: x.wmini,
        nbwins: x.nbwins,
        nextZone: x.nextZone,
        open: x.open,
      });
    }
    console.log(rates);
    SetZConfig(rates);
  } 
  async function sort(dd)
  {
      const promisesToAwait = [];
      for (let i = 0; i < dd.length; i++) {
        promisesToAwait.push(GetThumbNail(dd[i]));
      }
    return promisesToAwait;
  }

  async function  GetThumbNail (x){
    var final={};
        var path = "/atomicassets/v1/assets/" +parseInt(x.value);
        const response = await fetch(loginHandler.aa + path, {
            headers: {
                "Content-Type": "text/plain"
            },
            method: "POST",
        });
        const body = await response.json();
        console.log(body);
        final=x;
        final.img= body.data.template.immutable_data.img;
        final.name= body.data.template.immutable_data.name;
            return final;
    }
  
async function GetDivisions(userName) {
  try {
    if(!AccountStore.accountAddress) return;
    var zones= await getZonesConfig();
    var result=[];
    const tableRows = await loginHandler.wax.rpc.get_table_rows({
      json: true,
      code: loginHandler.Gamecontract,
      scope: loginHandler.Gamecontract,
      table: "sdivisions",
      key_type: "i64",
      index_position: 2,
      lower_bound: eosjsName.nameToUint64(userName),
      upper_bound: eosjsName.nameToUint64(userName),
      limit: 1000,
    });
    if (tableRows.rows.length === 0) {
    } else {
      for (let i = 0; i < tableRows.rows.length; i++) {
        var row=tableRows.rows[i];
        if(row.owner===userName)
        {
          var Zconfi=Zconfig.slice();
          if(Zconfi.length>0)
          {
            const zoneID= row.zoneID;
            var Zone= Zconfi.filter((z) => z.id === zoneID);
            if(Zone.length>0)
            {
              var ZoneName=Zone[0].name;
              row.name= ZoneName;
            }
          }

          var units= row.units.slice();
          const promises=await sort(units);
          const responses = await Promise.all(promises);
          row.units=responses;
          console.log(row.units);
          result.push(row);
        }
        }
      }
      console.log(result);
      setDivisions(result);
      var balances= await loginHandler.getPlayerBalances(userName);
      if(balances.length>0)
      setBlc(balances);
      var wbalance= await loginHandler.getPlayerWBalances(userName);
      if(wbalance.length>0)
      setWBlc(wbalance);
  } catch (e) {}
}

function getStatus(division)
{
  if(division.end_time===0 )
  {
    return "Idle " + division.name;
  }
  else if(division.end_time>0)
  {
    var tr= loginHandler.get_rtime(division.end_time);
    if(tr===0)
    {
      return "Claimable " + division.name;
    }
    else
    {
      return "In Progress " + division.name;
    }
  }
}

function updateInputValue(e) {
  const val = e.target.value;
  setInput(val);
}
  return (
        <div className="parent">
        <div class="">
                           <div class="topDesc">
                              <button className="header-wallet-logout" onClick={(e)=> {
                                GetDivisions(Input);
                        }}>Load</button>
                        <input value={Input} onChange={evt => updateInputValue(evt)}/>
                        </div>
                        </div>
                     <div>
                     <div class="left25">
                <div id="ArmedTable" class="tableHoF">
                <table>
                    <tbody>
                    <th colspan="2">InGame Balances</th> {blc.map((asset, index) => {
                        return (
                            <tr>
                                <td><span>{asset.symbol}</span></td>
                                <td><span>{parseFloat(asset.amount).toFixed(4)}</span></td>
                            </tr>
                        )})}
                    </tbody>
                </table>
            </div>
            <div id="ArmedTable" class="tableHoF">
                <table>
                    <tbody>
                    <th colspan="2">Wallet Balances</th> {Wblc.map((asset, index) => {
                        return (
                            <tr>
                                <td><span>{asset.symbol}</span></td>
                                <td><span>{parseFloat(asset.amount).toFixed(4)}</span></td>
                            </tr>
                        )})}
                    </tbody>
                </table>
            </div>
            </div>
                           {
                              Divisions.map((item, index) => {
                                return (  
                                  <>
                                   <div key={index} class="left25 tableHoF w100p5">
                                     <table>
                                       <tbody>
                                       <tr>
                                         <th><span>#</span></th>
                                         <th><span>Power</span></th>
                                         <th><span>Statut</span></th>
                                       </tr>
                                        <tr>
                                          <td><span>#{item.divID}</span></td>
                                          <td><span>{item.power/10000}</span></td>
                                          <td><span>{getStatus(item)}</span></td>
                                        </tr>
                                          </tbody>
                                        </table>
                                     <table key={index}>
                                       <tbody>
                              {
                                    item.units.map((item, index) => {
                                      return (  
                                          <tr>
                                            <td><span><img src={`https://dov-gw.mypinata.cloud/ipfs/${item.img}`} alt="" className="img-bz"/></span></td>
                                            <td>{item.value}</td>
                                          </tr>
                              )})
                              }  
                                                                                        </tbody>
                                                      </table> 
                        </div>
                       </>
                        )})

                            }
                                          </div>
                           </div>
  );
}

export default observer(Player_PVE);
