import "../../main.css";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";

function Arrow(arrowHover,arrow) {
  const [over, setOver] = useState(false);
  return (
    <div
      onMouseOver={() => setOver(true)}
      onMouseOut={() => setOver(false)}
    >
         <img
                  src={over?
                    require('../../assets/dov/button_theater_left.png')
                    :
                    require('../../assets/dov/button_theater_right.png')
                }
          alt="arrow"
          width="50"
          height="50"
        />
    </div>   
  )
}
export default observer(Arrow);