import "./index.sass";
import "../../main.css";
import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import * as waxjs from "@waxio/waxjs/dist";
import { useEffect, useState } from "react";
import LoginHandler from "../../functions/login";
import { BrowserRouter as Router, Link } from "react-router-dom";

function Building() {
  const { AccountStore } = StoreContext();
  const [loading, setLoading] = useState(false);
  const [craftData, setCraftData] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  var searchName = "";
  const [searchResult, setResult] = useState(null);
  const loginHandler = new LoginHandler();

  useEffect(() => {
    if (AccountStore.accountAddress) {
      loginHandler.getAllBuildings();
    }
  }, [AccountStore.accountAddress]);

  function shiftPage(direction) {
    var maxPages = parseInt(AccountStore.allUsers.length / 30);
    if (direction === "Next") {
      if (pageNo < maxPages * 30) {
        setPageNo(pageNo + 30);
      }
    }
    if (direction === "Prev") {
      if (pageNo - 30 >= 0) {
        setPageNo(pageNo - 30);
      }
    }
  }

  function Find() {
    var name = searchName;
    console.log(name);
    if (name == "") return;
    var i = 0;
    for (i = 0; i < AccountStore.allUsers.length; i++) {
      if (AccountStore.allUsers[i].player == name) {
        setResult(AccountStore.allUsers[i]);
      }
    }
    setResult(null);
    console.log(searchResult);
  }

  return (
    <div className="parent">
      <div class="left50">
        <div id="topStats">
          <div class="arrow"></div>
          <div class="topTitle">
            <span>All Balances</span>
          </div>
        </div>
      </div>

      <div class="clearfix mgb15"></div>
      <div class="left25">
        <div id="ArmedTable" class="tableHoF">
          <table>
            <tbody>
              <th colspan="2">Ingame Balances</th>{" "}
              {AccountStore.allBalances.map((asset, index) => {
                return (
                  <tr>
                    <td>
                      <span>{asset.symbol}</span>
                    </td>
                    <td>
                      <span>{parseFloat(asset.amount).toFixed(4)}</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div class="clearfix mgb15"></div>
        <div class="left25">
          <div id="ArmedTable" class="tableHoF">
            <table>
              <tbody>
                <th colspan="2">Wallet Balances</th>{" "}
                {AccountStore.walletBalances.map((asset, index) => {
                  return (
                    <tr>
                      <td>
                        <span>{asset.symbol}</span>
                      </td>
                      <td>
                        <span>{parseFloat(asset.amount).toFixed(4)}</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="right50">
        <div class="tableHoF tableDFPVE">
          <table>
            <tbody>
              <tr>
                <th>
                  <td>
                    <span>
                      <button
                        onClick={(e) => {
                          Find();
                        }}
                      >
                        {" "}
                        Find{" "}
                      </button>
                      <input
                        type="text"
                        style={{ width: "100px" }}
                        onChange={(e) => {
                          searchName = e.target.value;
                        }}
                      />
                    </span>
                  </td>
                  <td>
                    <span>{}</span>
                  </td>
                </th>
                {searchResult ? (
                  <>
                    <td>
                      <span>{searchResult.player}</span>
                    </td>
                    {searchResult.balances.map((asset, index) => {
                      return (
                        <td>
                          <span>
                            {asset.symbol} :{" "}
                            {parseFloat(asset.amount).toFixed(4)}
                          </span>
                        </td>
                      );
                    })}
                  </>
                ) : (
                  <th>
                    <span>Player</span>
                  </th>
                )}
              </tr>
              {AccountStore.allUsers
                .slice(pageNo, pageNo + 30)
                .map((zone, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span>{pageNo + index + 1}</span>
                      </td>
                      <td>
                        <span>{zone.player}</span>
                      </td>
                      {zone.balances.map((asset, index) => {
                        return (
                          <td>
                            <span>
                              {asset.symbol} :{" "}
                              {parseFloat(asset.amount).toFixed(4)}
                            </span>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div class="topTitle">
          <button onClick={() => shiftPage("Prev")}>Prev</button>
          <span>
            {pageNo / 30} / {parseInt(AccountStore.allUsers.length / 30)}{" "}
          </span>
          <button onClick={() => shiftPage("Next")}>Next</button>
        </div>
      </div>
    </div>
  );
}

export default observer(Building);
